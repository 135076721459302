<div class="row">
    <div class="row cal-container">
      <div class="row menu-block">
        <div @click="switchTab('calculator')" class="menu-tab-item">
          <div :class="tabActive == 'calculator' ? 'tab-active':'tab'">
            <i class="fa fa-calculator"></i>
            {{$t('menu.calculate')}}
          </div>
        </div>
        <div @click="switchTab('user')" class="menu-tab-item">
          <div :class="tabActive == 'user' ? 'tab-active':'tab'">
            <i class="fa fa-user"></i> {{$t('menu.user')}}
          </div>
        </div>
        <div v-if="type != 'user'" @click="switchTab('agent')" class="menu-tab-item">
          <div :class="tabActive == 'agent' ? 'tab-active':'tab'">
            <!-- <i class="fa fa-user-plus"></i> -->
            <img src="https://cdn-icons-png.flaticon.com/512/410/410745.png" style="width: 30px;" />
            {{$t('menu.agent')}}
          </div>
        </div>
      </div>

      <Calculator v-if="tabActive == 'calculator'"></Calculator>

      <div v-if="tabActive == 'user'" class="row pt10">
        <div class="sub-tab-container">
          <div class="block-sub-tab">
            <div @click="switchSubMenu('single')" :class="subUserTabActive == 'single' ? 'sub-tab-active':'sub-tab'">{{$t('user.subTab.single')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('multi')" :class="subUserTabActive == 'multi' ? 'sub-tab-active':'sub-tab'">{{$t('user.subTab.multi')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('generateBetString')" :class="subUserTabActive == 'generateBetString' ? 'sub-tab-active':'sub-tab'">{{$t('user.subTab.generateBetString')}}</div>
          </div>
        </div>

        <div v-if="subUserTabActive == 'single'" class="row sub-tab-block">
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.playAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="user.amount" type="number" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.oddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="user.odd" type="number" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.bonusRate')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="user.rate" type="number" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('winlost')}}
            </div>
            <div class="col-right-item pt6">
              <div class="mh12 go-left"><input v-model="user.status" :value="1" id="user-win" type="radio" /> <label for="user-win">{{$t('win')}}</label></div>
              <div class="mh12 go-left"><input v-model="user.status" :value="0" id="user-lost" type="radio" /> <label for="user-lost">{{$t('lost')}}</label></div>
            </div>
          </div>
          <div class="row" style="text-align: center;margin-bottom: 32px;">
            <button @click="calculate" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('user')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
            <p class="font18 bold m0 mb10">{{$t('calculateResult')}}</p>
            <table class="table">
              <tr>
                <td width="50%">{{$t('user.profitBeforeBonus')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.user.profitBeforeBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.user.profitBeforeBonus}}</span>
                  <small v-if="user.status == 1" class="txt-zoom-hover">{{$t('formulas')}}: ({{user.amount}} * {{user.odd}}) - {{user.amount}} = {{result.user.profitBeforeBonus}}</small>
                  <small v-else class="txt-zoom-hover">{{$t('formulas')}}: -1 * {{user.amount}} = {{result.user.profitBeforeBonus}}</small>
                </td>
              </tr>
              <tr>
                <td width="50%">{{$t('user.bonusAmount')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.user.bonusAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.user.bonusAmount}}</span>
                  <small class="txt-zoom-hover">{{$t('formulas')}}: {{user.amount}} * ({{user.rate}}/100) = {{result.user.bonusAmount}}</small>
                </td>
              </tr>
              <tr>
                <td width="50%">{{$t('user.profitAfterBonus')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.user.amount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.user.amount}}</span>
                  <small v-if="user.status == 1" class="txt-zoom-hover">{{$t('formulas')}}: (({{user.amount}} * {{user.odd}}) - {{user.amount}}) +  (({{user.rate}}/100) * {{user.amount}}) = {{result.user.amount}}</small>
                  <small v-else class="txt-zoom-hover">{{$t('formulas')}}: (-{{user.amount}}) +  (({{user.rate}}/100) * {{user.amount}}) = {{result.user.amount}}</small>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div v-if="subUserTabActive == 'multi'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="row">
              <textarea v-model="userMulti.inputFormat" class="input" style="background: #e2e4ef;" rows="10"></textarea>
            </div>
          </div>

          <!-- <div v-if="userMulti.error[0]" class="row">
            <p v-for="error in userMulti.error" class="font12 mb0 txt-danger">Error : {{error}}</p>
          </div> -->

          <div class="row mb12">
            <p class="mb0 font10" v-html="formattedText(`Input box set a multi-row edit box , Every row formation like this : PlayItemName|Ball|PlayAmount|OddsRate|BonusRate|WinOrLose`)"></p>
            <p class="mb0 font10" v-html="formattedText(`输入框支持多行数据，每一行的格式如下 ：项目名|球号|原始投注金额|赔率|退水率|输或赢`)"></p>
          </div>

          <div class="row txt-center mb32">
            <button @click="userMultiCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('userMulti')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('user.userMulti.totalPlayAmount')}}</td>
                  <td width="50%" 
                    @mouseover="tooltipActive = 'userMulti.totalPlayAmount'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userMulti.totalPlayAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.userMulti.totalPlayAmount }}</span>
                    <small>{{$t('formulas')}} : sum(PlayAmount)</small>

                    <div v-if="tooltipActive == 'userMulti.totalPlayAmount'" class="tooltip-hover">
                      <p v-for="(totalPlayAmountItem, totalPlayAmountIndex ) in result.userMulti.totalPlayAmountFormalas" class="result-item">{{totalPlayAmountIndex+1}}).  {{totalPlayAmountItem}}</p>
                      <p class="result-total">Total : {{result.userMulti.totalPlayAmount}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('user.userMulti.totalBonus')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'userMulti.totalBonus'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userMulti.totalBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.userMulti.totalBonus }}</span>
                    <small>{{$t('formulas')}} : sum(PlayAmount * BonusRate/100)</small>
                  
                    <div v-if="tooltipActive == 'userMulti.totalBonus'" class="tooltip-hover">
                      <p v-for="(totalBonusItem, totalBonusIndex ) in result.userMulti.totalBonusFormalas" class="result-item">{{totalBonusIndex+1}}).  {{totalBonusItem}}</p>
                      <p class="result-total">Total : {{result.userMulti.totalBonus}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('user.userMulti.totalProfit')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'userMulti.totalProfit'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userMulti.totalProfit > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.userMulti.totalProfit}}</span>
                    <div class="row">
                      <p class="m0 font8">If Win : sum(((PlayAmount * OddRate) - PlayAmount) + ((BonusRate/100)*PlayAmount))</p>
                      <p class="m0 font8">If Lose : sum(-PlayAmount + ((BonusRate/100)*PlayAmount))</p>
                      <p class="m0 font8">{{$t('formulas')}} : sum(win & lose)</p>
                    </div>

                    <div v-if="tooltipActive == 'userMulti.totalProfit'" class="tooltip-hover">
                      <p v-for="(totalProfitItem, totalProfitIndex ) in result.userMulti.totalProfitFormalas" class="result-item">{{totalProfitIndex+1}}).  {{totalProfitItem}}</p>
                      <p class="result-total">Total : {{result.userMulti.totalProfit}}</p>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subUserTabActive == 'generateBetString'" class="row sub-tab-block">
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.generateBetString.ball')}}
            </div>
            <div class="col-right-item">
              <!-- <div @click="pasteText" class="ic-paste pointer" title="Paste">
                <i class="fa fa-paste"></i>
              </div> -->
              
              <!-- <input v-model="generateBetString.balls" type="text" class="ctm-input pr40"/> -->
              <textarea v-model="generateBetString.balls" rows="3" class="ctm-input pr40" style="line-height: 20px;height: auto;"></textarea>
              <div style="width: 80%;">
                <span @click="pasteText" class="font12 go-right txt-info mt-6 pointer">{{$t('user.generateBetString.paste')}}</span>
              </div>
              
            </div>
          </div>
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.generateBetString.amount')}}
            </div>
            <div class="col-right-item">
              <input v-model="generateBetString.amount" type="number" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row mv6">
            <div class="col-left-item">
              {{$t('user.generateBetString.dropList')}}
            </div>
            <div class="col-right-item">
              <c-select v-model:value="generateBetString.splitCodeSelected" :options="generateBetString.dropList" optionLabel="label" optionValue="val" class="ctm-input" style="width: 80%;"></c-select>
            </div>
          </div>
          <div class="row" style="text-align: center;margin-bottom: 32px;">
            <button @click="generateBetStringCalculation" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('generateBetString')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
            <div class="row">
              <p class="font18 bold m0 mb10">
                {{$t('user.result')}}
  
                <div v-if="result.generateBetString?.result" @click="copyText(result.generateBetString.result)" class="bg-secondary pv3 ph6 radius4 btn-small go-right font12 pointer mt6">
                  <i class="fa fa-copy mr6"></i>{{$t('user.generateBetString.copy')}}
                </div>
              </p>
              <div class="row">
                <textarea ref="generateBetStringResult" rows="4" v-model="result.generateBetString.result" class="input"></textarea>
              </div>
            </div>
            <div class="row mt6">
              <p class="font18 bold m0 mb10">
                {{$t('user.result')}} 2

                <div v-if="result.generateBetString?.result2" @click="copyText(result.generateBetString.result2)" class="bg-secondary pv3 ph6 radius4 btn-small go-right font12 pointer mt6">
                  <i class="fa fa-copy mr6"></i>{{$t('user.generateBetString.copy')}}
                </div>
              </p>
              <div class="row">
                <textarea ref="generateBetStringResult" rows="4" v-model="result.generateBetString.result2" class="input"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabActive == 'agent'" class="row pt10">
        <div class="sub-tab-container">
          <div class="block-sub-tab">
            <div @click="switchSubMenu('tema')" :class="subAgentTabActive == 'tema' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.temaPredictProfit')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('overceed')" :class="subAgentTabActive == 'overceed' ? 'sub-tab-active':'sub-tab'">{{$t('agent.overceed.overceed')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('shareholder')" :class="subAgentTabActive == 'shareholder' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.onlyShareholder')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('bonusdiff')" :class="subAgentTabActive == 'bonusdiff' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.onlyBonusDiff')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('bonusAndOddDiff')" :class="subAgentTabActive == 'bonusAndOddDiff' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.bonusOddDiff')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('odddiff')" :class="subAgentTabActive == 'odddiff' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.onlyOddDiff')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('hedge')" :class="subAgentTabActive == 'hedge' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.onlyHedge')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('userProfit')" :class="subAgentTabActive == 'userProfit' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.userProfit')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('agentProfit')" :class="subAgentTabActive == 'agentProfit' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.agentProfit')}}</div>
          </div>
          <div class="block-sub-tab">
            <div @click="switchSubMenu('agentOwnPartProfit')" :class="subAgentTabActive == 'agentOwnPartProfit' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.agentOwnPartProfit')}}</div>
          </div>
          
          <div hidden class="block-sub-tab">
            <div @click="switchSubMenu('complex')" :class="subAgentTabActive == 'complex' ? 'sub-tab-active':'sub-tab'">{{$t('agent.subTab.complexCondition')}}</div>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'tema'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.tema.hitBallCollectAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="tema.hitBallCollectAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.tema.otherBallCollectAmounts')}}
            </div>
            <div class="col-right-item">
              <input v-model="tema.otherBallCollectAmounts" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.tema.oddRateAmountToday')}}
            </div>
            <div class="col-right-item">
              <input v-model="tema.oddRateAmountToday" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.tema.bonusRateToday')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="tema.bonusRateToday" type="text" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row txt-center mb32">
            <button @click="temaPredictProfitCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('tema')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('agent.tema.resultPredict')}}</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="result.tema.predictAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.tema.predictAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: {{tema.hitBallCollectAmount}} * {{tema.oddRateAmountToday}} - ({{tema.hitBallCollectAmount}}+{{tema.otherBallCollectAmounts}}) * (1+({{tema.bonusRateToday}}/100)) = {{result.tema.predictAmount}}</small>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'overceed'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.overceed.dropList')}} <span class="txt-danger">*</span>
            </div>
            <div class="col-right-item">
              <div class="c-select-control">
                <c-select v-model:value="overceed.numberOfBall" :options="overceed.dropList" optionLabel="label" optionValue="val"  class="ctm-input"></c-select>
              </div>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.overceed.oddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="overceed.oddRateAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.overceed.bonusRate')}} (%)
            </div>
            <div class="col-right-item">
              <input v-model="overceed.bonusRate" type="text" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row txt-center mb32">
            <button @click="overceedCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('overceed')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('agent.overceed.overceed')}}</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="result.overceed.result >= 1 ? 'txt-danger' : 'txt-success'" class="bold pr6">{{result.overceed.result}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: 1 * {{overceed.oddRateAmount}} / {{overceed.numberOfBall.toFixed(4)}} + ({{overceed.bonusRate}}/100) = {{result.overceed.result}}</small>
                  </td>
                </tr>
              </table>
          </div>
        </div>
        
        <div v-if="subAgentTabActive == 'shareholder'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.shareholder.originalProfitAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="shareholder.originalAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.shareholder.originalBonusAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="shareholder.bonusAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.shareholder.parentShareholder')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="shareholder.parentShareholderRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.shareholder.currentShareholder')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="shareholder.shareholderRate" type="text" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row txt-center mb32">
            <button @click="shareholderCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('shareholder')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('agent.shareholder.resultOccupiedProfitAmount')}}</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="result.shareholder.playAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.shareholder.playAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: {{shareholder.originalAmount}} * ({{shareholder.shareholderRate}}/100) = {{result.shareholder.playAmount}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.shareholder.resultOccupiedBonusAmount')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.shareholder.profitAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.shareholder.profitAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: ({{shareholder.originalAmount}} * ({{shareholder.shareholderRate}}/100)) - {{shareholder.bonusAmount}} * ({{shareholder.shareholderRate}}/100) = {{result.shareholder.profitAmount}}</small>
                  </td>
                </tr>
                <tr>
                  <td width="50%">{{$t('agent.shareholder.resultContributeProfitAmountToParent')}}</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="result.shareholder.playAmountToParent > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.shareholder.playAmountToParent}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: {{shareholder.originalAmount}} * ({{shareholder.parentShareholderRate}}/100) = {{result.shareholder.playAmountToParent}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.shareholder.resultContributeRealProfitAmountToParent')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.shareholder.profitAmountToParent > -1 ? 'txt-success' : 'txt-danger'" class=" bold pr6">{{result.shareholder.profitAmountToParent}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}}: ({{shareholder.originalAmount}} * ({{shareholder.parentShareholderRate}}/100)) - {{shareholder.bonusAmount}} * ({{shareholder.parentShareholderRate}}/100) = {{result.shareholder.profitAmountToParent}}</small>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'bonusdiff'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.originalAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="bonusDiff.originalAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.parentBonusRate')}}(%) 
            </div>
            <div class="col-right-item">
              <input v-model="bonusDiff.parentBonusRate" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.currentBonus')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="bonusDiff.bonusRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.parentShareholderRate')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="bonusDiff.parentSharehold" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row" style="text-align: center;margin-bottom: 32px;">
            <button @click="bonusDiffCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('bonusdiff')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">Bonus amount pay to below</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="-1 * result.bonusDiff.bonusAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{$helper.formatAmount(-1 * result.bonusDiff.bonusAmount, 3)}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : {{bonusDiff.originalAmount}} * ({{bonusDiff.bonusRate}}/100) = {{result.bonusDiff.bonusAmount}}</small>
                  </td>
                </tr>
                <tr>
                  <td>Bonus amount parent pay to me</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusDiff.amountToParent > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusDiff.amountToParent}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : ({{bonusDiff.originalAmount}} * ({{bonusDiff.parentSharehold}}/100)) * ({{bonusDiff.parentBonusRate}}/100)  = {{result.bonusDiff.amountToParent}}</small>
                  </td>
                </tr>
                <tr>
                  <td>Bonus commission amount</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusDiff.commissionAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusDiff.commissionAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : {{result.bonusDiff.amountToParent}} - {{result.bonusDiff.bonusAmount}} = {{result.bonusDiff.commissionAmount}}</small>
                  </td>
                </tr>
              </table>

              <p class="txt-right font12 mv12">{{$t('agent.bonusDiff.reminderInfo')}}</p>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'bonusAndOddDiff'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.originalAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.originalAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.parentBonusRate')}}(%) 
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.parentBonusRate" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.currentBonus')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.bonusRate" type="text" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.parentOddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.parentOddRate" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.currentOddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.oddRate" type="text" class="ctm-input"/>
            </div>
          </div>

          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.bonusDiff.parentShareholderRate')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="bonusAndOddDiff.parentSharehold" type="text" class="ctm-input"/>
            </div>
          </div>

          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('winlost')}}
            </div>
            <div class="col-right-item pt6">
              <div class="mh12 go-left"><input v-model="bonusAndOddDiff.oddWinLostStatus" :value="1" id="oddDiff-win" type="radio" /> <label for="oddDiff-win">{{$t('win')}}</label></div>
              <div class="mh12 go-left"><input v-model="bonusAndOddDiff.oddWinLostStatus" :value="0" id="oddDiff-lost" type="radio" /> <label for="oddDiff-lost">{{$t('lost')}}</label></div>
            </div>
          </div>

          <div class="row" style="text-align: center;margin-bottom: 32px;">
            <button @click="bonusAndOddDiffCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('bonusAndOddDiff')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('agent.bonusAndOddDiff.bonusAmountPayToBelow')}}</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="-1 * result.bonusAndOddDiff.bonusAmountPayToBelow > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{$helper.formatAmount(-1 * result.bonusAndOddDiff.bonusAmountPayToBelow, 3)}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : {{bonusAndOddDiff.originalAmount}} * ({{bonusAndOddDiff.bonusRate}}/100) = {{result.bonusAndOddDiff.bonusAmountPayToBelow}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.bonusAndOddDiff.bonusAmountParentPayToMe')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.bonusAmountParentPayToMe > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.bonusAmountParentPayToMe}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : ({{bonusAndOddDiff.originalAmount}} * ({{bonusAndOddDiff.parentSharehold}}/100)) * ({{bonusAndOddDiff.parentBonusRate}}/100)  = {{result.bonusAndOddDiff.bonusAmountParentPayToMe}}</small>
                  </td>
                </tr>
                <!-- <tr>
                  <td>{{$t('agent.bonusAndOddDiff.bonusCommissionAmount')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.bonusCommissionAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.bonusCommissionAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : {{result.bonusAndOddDiff.bonusAmountParentPayToMe}} - {{result.bonusAndOddDiff.bonusAmountPayToBelow}} = {{result.bonusAndOddDiff.bonusCommissionAmount}}</small>
                  </td>
                </tr> -->
                <tr>
                  <td>{{$t('agent.bonusAndOddDiff.bonusCommissionAmount')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.bonusCommissionAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.bonusCommissionAmount}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : ({{bonusAndOddDiff.originalAmount}} * ({{bonusAndOddDiff.parentSharehold}}/100)) * (({{bonusAndOddDiff.parentBonusRate}}/100) - ({{bonusAndOddDiff.bonusRate}}/100)) = {{result.bonusAndOddDiff.bonusCommissionAmount}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.bonusAndOddDiff.OddAmountPayToBelow')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="-1 * (result.bonusAndOddDiff.oddAmountPayToBelow) > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ $helper.formatAmount(-1 * result.bonusAndOddDiff.oddAmountPayToBelow, 3)}}</span>
                    <small v-if="bonusAndOddDiff.oddWinLostStatus == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : ({{bonusAndOddDiff.originalAmount}}*{{bonusAndOddDiff.oddRate}}) - {{bonusAndOddDiff.originalAmount}} = {{result.bonusAndOddDiff.oddAmountPayToBelow}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.bonusAndOddDiff.oddAmountParentPayToMe')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.oddAmountParentPayToMe > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.oddAmountParentPayToMe}}</span>
                    <small v-if="bonusAndOddDiff.oddWinLostStatus == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : (({{bonusAndOddDiff.originalAmount}}*({{bonusAndOddDiff.parentSharehold}}/100)) * {{bonusAndOddDiff.parentOddRate}}) - {{bonusAndOddDiff.originalAmount}} = {{result.bonusAndOddDiff.oddAmountParentPayToMe}}</small>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.bonusAndOddDiff.oddCommissionAmountTotalProfit')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.oddCommissionAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.oddCommissionAmount}}</span>
                    <small v-if="bonusAndOddDiff.oddWinLostStatus == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : ({{bonusAndOddDiff.originalAmount}} * ({{bonusAndOddDiff.parentSharehold}}/100)) * (({{bonusAndOddDiff.parentOddRate}}) - ({{bonusAndOddDiff.oddRate}})) = {{result.bonusAndOddDiff.oddCommissionAmount}}</small>
                  </td>
                </tr>
                <!-- <tr>
                  <td>{{$t('agent.bonusAndOddDiff.totalProfit')}}</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.bonusAndOddDiff.totalProfit > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.bonusAndOddDiff.totalProfit}}</span>
                    <small v-if="bonusAndOddDiff.oddWinLostStatus == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : {{result.bonusAndOddDiff.bonusCommissionAmount}} + {{result.bonusAndOddDiff.oddCommissionAmount}} = {{result.bonusAndOddDiff.totalProfit}}</small>
                  </td>
                </tr> -->
              </table>

              <p class="txt-right font12 mv12">{{$t('agent.bonusDiff.reminderInfo')}}</p>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'odddiff'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.originalAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="oddDiff.originalAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.parentOddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="oddDiff.parentOddRate" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.currentOddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="oddDiff.oddRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.oddDiff.parentShareholder')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="oddDiff.parentSharehold" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('winlost')}}
            </div>
            <div class="col-right-item pt6">
              <div class="mh12 go-left"><input v-model="oddDiff.status" :value="1" id="oddDiff-win" type="radio" /> <label for="oddDiff-win">{{$t('win')}}</label></div>
              <div class="mh12 go-left"><input v-model="oddDiff.status" :value="0" id="oddDiff-lost" type="radio" /> <label for="oddDiff-lost">{{$t('lost')}}</label></div>
            </div>
          </div>
          <div class="row txt-center mb32">
            <button @click="oddDiffCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('odddiff')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">amount pay to below</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="-1 * (result.oddDiff.oddAmountPayToBelow) > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ $helper.formatAmount(-1 * result.oddDiff.oddAmountPayToBelow, 3)}}</span>
                    <small v-if="oddDiff.status == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : ({{oddDiff.originalAmount}}*{{oddDiff.oddRate}}) - {{oddDiff.originalAmount}} = {{result.oddDiff.oddAmountPayToBelow}}</small>
                  </td>
                </tr>
                <tr>
                  <td>amount parent pay to me</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.oddDiff.oddAmountParentPayToMe > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.oddDiff.oddAmountParentPayToMe}}</span>
                    <small v-if="oddDiff.status == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : (({{oddDiff.originalAmount}}*({{oddDiff.parentSharehold}}/100)) * {{oddDiff.parentOddRate}}) - {{oddDiff.originalAmount}} = {{result.oddDiff.oddAmountParentPayToMe}}</small>
                  </td>
                </tr>
                <tr>
                  <td>Odd commission amount</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.oddDiff.oddCommissionAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.oddDiff.oddCommissionAmount}}</span>
                    <small v-if="oddDiff.oddWinLostStatus == 0" class="txt-zoom-hover">{{$t('formulas')}} : 0</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : ({{oddDiff.originalAmount}} * ({{oddDiff.parentSharehold}}/100)) * (({{oddDiff.parentOddRate}}) - ({{oddDiff.oddRate}})) = {{result.oddDiff.oddCommissionAmount}}</small>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'hedge'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.hedge.hedgeAmount')}}
            </div>
            <div class="col-right-item">
              <input v-model="hedge.amount" type="number" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.hedge.parentOddRate')}}
            </div>
            <div class="col-right-item">
              <input v-model="hedge.odd" type="number" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('agent.hedge.bonus')}}(%)
            </div>
            <div class="col-right-item">
              <input v-model="hedge.rate" type="number" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              {{$t('winlost')}}
            </div>
            <div class="col-right-item pt6">
              <div class="mh12 go-left"><input v-model="hedge.status" :value="1" id="hedge-win" type="radio" /> <label for="hedge-win">{{$t('win')}}</label></div>
              <div class="mh12 go-left"><input v-model="hedge.status" :value="0" id="hedge-lost" type="radio" /> <label for="hedge-lost">{{$t('lost')}}</label></div>
            </div>
          </div>
          <div class="row txt-center mb32">
            <button @click="hedgeCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('hedge')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>

          <div class="row mb32">
            <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
            <table class="table">
              <tr>
                <td width="50%">{{$t('user.profitBeforeBonus')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.hedge.profitBeforeBonus > -1 ? 'txt-success' : 'txt-danger'" class="txt-danger bold pr6">{{result.hedge.profitBeforeBonus}}</span>
                  <small v-if="hedge.status == 1" class="txt-zoom-hover">{{$t('formulas')}}: ({{hedge.amount}} * {{hedge.odd}}) - {{hedge.amount}} = {{result.hedge.profitBeforeBonus}}</small>
                  <small v-else class="txt-zoom-hover">{{$t('formulas')}}: -1 * {{hedge.amount}} = {{result.hedge.profitBeforeBonus}}</small>
                </td>
              </tr>
              <tr>
                <td width="50%">{{$t('user.bonusAmount')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.hedge.bonusAmount > -1 ? 'txt-success' : 'txt-danger'" class="txt-danger bold pr6">{{result.hedge.bonusAmount}}</span>
                  <small class="txt-zoom-hover">{{$t('formulas')}}: {{hedge.amount}} * ({{hedge.rate}}/100) = {{result.hedge.bonusAmount}}</small>
                </td>
              </tr>
              <tr>
                <td width="50%">{{$t('user.profitAfterBonus')}}</td>
                <td width="50%" style="border-left: 1px solid var(--secondary)">
                  <span :class="result.hedge.amount > -1 ? 'txt-success' : 'txt-danger'" class="txt-danger bold pr6">{{result.hedge.amount}}</span>
                  <small v-if="hedge.status == 1" class="txt-zoom-hover">{{$t('formulas')}}: (({{hedge.amount}} * {{hedge.odd}}) - {{hedge.amount}}) +  (({{hedge.rate}}/100) * {{hedge.amount}}) = {{result.hedge.amount}}</small>
                  <small v-else class="txt-zoom-hover">{{$t('formulas')}}: (-{{hedge.amount}}) +  (({{hedge.rate}}/100) * {{hedge.amount}}) = {{result.hedge.amount}}</small>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'userProfit'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="row">
              <textarea v-model="userProfit.inputFormat" class="input" style="background: #e2e4ef;" rows="10"></textarea>
            </div>
          </div>

          <!-- <div v-if="userProfit.error[0]" class="row">
            <p v-for="error in userProfit.error" class="font12 mb0 txt-danger">Error : {{error}}</p>
          </div> -->

          <div class="row mb12">
            <p class="mb0 font10" v-html="formattedText(`Input box set a multi-row edit box , Every row formation like this : PlayItemName|Ball|PlayAmount|OddsRate|BonusRate|WinOrLose`)"></p>
            <p class="mb0 font10" v-html="formattedText(`输入框支持多行数据，每一行的格式如下 ：项目名|球号|原始投注金额|赔率|退水率|输或赢`)"></p>
          </div>

          <div class="row txt-center mb32">
            <button @click="userProfitCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('userProfit')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">{{$t('agent.userProfit.totalPlayAmount')}}</td>
                  <td width="50%" 
                    @mouseover="tooltipActive = 'userProfit.totalPlayAmount'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userProfit.totalPlayAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.userProfit.totalPlayAmount }}</span>
                    <small>{{$t('formulas')}} : sum(PlayAmount)</small>

                    <div v-if="tooltipActive == 'userProfit.totalPlayAmount'" class="tooltip-hover">
                      <p v-for="(totalPlayAmountItem, totalPlayAmountIndex ) in result.userProfit.totalPlayAmountFormalas" class="result-item">{{totalPlayAmountIndex+1}}).  {{totalPlayAmountItem}}</p>
                      <p class="result-total">Total : {{result.userProfit.totalPlayAmount}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.userProfit.totalBonus')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'userProfit.totalBonus'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userProfit.totalBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.userProfit.totalBonus }}</span>
                    <small>{{$t('formulas')}} : sum(PlayAmount * BonusRate/100)</small>
                  
                    <div v-if="tooltipActive == 'userProfit.totalBonus'" class="tooltip-hover">
                      <p v-for="(totalBonusItem, totalBonusIndex ) in result.userProfit.totalBonusFormalas" class="result-item">{{totalBonusIndex+1}}).  {{totalBonusItem}}</p>
                      <p class="result-total">Total : {{result.userProfit.totalBonus}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.userProfit.totalProfit')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'userProfit.totalProfit'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.userProfit.totalProfit > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.userProfit.totalProfit}}</span>
                    <div class="row">
                      <p class="m0 font8">If Win : sum(((PlayAmount * OddRate) - PlayAmount) + ((BonusRate/100)*PlayAmount))</p>
                      <p class="m0 font8">If Lose : sum(-PlayAmount + ((BonusRate/100)*PlayAmount))</p>
                      <p class="m0 font8">{{$t('formulas')}} : sum(win & lose)</p>
                    </div>

                    <div v-if="tooltipActive == 'userProfit.totalProfit'" class="tooltip-hover">
                      <p v-for="(totalProfitItem, totalProfitIndex ) in result.userProfit.totalProfitFormalas" class="result-item">{{totalProfitIndex+1}}).  {{totalProfitItem}}</p>
                      <p class="result-total">Total : {{result.userProfit.totalProfit}}</p>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'agentProfit'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="row">
              <textarea v-model="agentProfit.inputFormat" class="input" style="background: #e2e4ef;" rows="10"></textarea>
            </div>
          </div>

          <div class="row mb12">
            <p class="mb0 font10" v-html="formattedText(`Input box set a multi-row edit box , Every row formation like this : Item|Ball|OriginalAmount|ParentOddsRate|BelowOddsRate|ParentBonusRate|BelowBonusRate|ForwardPercent|WinOrLose`)"></p>
            <p class="mb0 font10" v-html="formattedText(`输入框支持多行数据，每一行的格式如下：项目名|球号|原始投注金额|上级给本级的赔率|本级给下级的赔率|上级给本级的退水率|本级给下级的退水率|上缴货百分比|输或赢`)"></p>
          </div>

          <div class="row txt-center mb32">
            <button @click="agentProfitCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('agentProfit')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="40%">{{$t('agent.agentProfit.totalForwardAmount')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'agentProfit.totalForwardAmount'" 
                    @mouseout="tooltipActive = ''"
                    width="60%" style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentProfit.totalPlayAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentProfit.totalPlayAmount }}</span>
                    <small>{{$t('formulas')}} : sum(PlayAmount*(forwardPercent/100))</small>
                    
                    <div v-if="tooltipActive == 'agentProfit.totalForwardAmount'" class="tooltip-hover">
                      <p v-for="(totalPlayAmountFormalasItem, totalPlayAmountFormalasIndex ) in result.agentProfit.totalPlayAmountFormalas" class="result-item">{{totalPlayAmountFormalasIndex+1}}).  {{totalPlayAmountFormalasItem}}</p>
                      <p class="result-total">Total : {{result.agentProfit.totalPlayAmount}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.agentProfit.totalBonusParentPayToMe')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'agentProfit.totalBonusPayToMe'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentProfit.totalBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentProfit.totalBonus }}</span>
                    <small>{{$t('formulas')}} : sum((playAmount * (forwardPercent/100)) * (parentBonusRate/100))</small>
                    
                    <div v-if="tooltipActive == 'agentProfit.totalBonusPayToMe'" class="tooltip-hover">
                      <p v-for="(totalBonusItem, totalBonusIndex ) in result.agentProfit.totalBonusFormalas" class="result-item">{{totalBonusIndex+1}}).  {{totalBonusItem}}</p>
                      <p class="result-total">Total : {{result.agentProfit.totalBonus}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.agentProfit.totalCommissionBonus')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'agentProfit.totalCommissionBonus'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentProfit.totalCommissionBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentProfit.totalCommissionBonus }}</span>
                    <small>{{$t('formulas')}} : sum((PlayAmount * (ForwardPercent/100)) * ((ParentBonusRate/100) - (BelowBonusRate/100)))</small>
                    <div v-if="tooltipActive == 'agentProfit.totalCommissionBonus'" class="tooltip-hover">
                      <p v-for="(totalBonusItem, totalBonusIndex ) in result.agentProfit.totalCommissionBonusFormalas" class="result-item">{{totalBonusIndex+1}}).  {{totalBonusItem}}</p>
                      <p class="result-total">Total : {{result.agentProfit.totalCommissionBonus}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.agentProfit.totalCommissionOdd')}}</td>
                  <td 
                    @mouseover="tooltipActive = 'agentProfit.totalCommissionOdd'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentProfit.totalCommissionOdd > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentProfit.totalCommissionOdd }}</span>
                    <p class="m0 font8">If Win : sum((playAmount * forwardPercent) * (parentOddRate - belowOddRate))</p>
                    <p class="m0 font8">If Lose : sum(0)</p>
                    <p class="m0 font8">{{$t('formulas')}} : sum(Win&Lose)</p>


                    <div v-if="tooltipActive == 'agentProfit.totalCommissionOdd'" class="tooltip-hover">
                      <p v-for="(totalCommissionOddItem, totalCommissionOddIndex ) in result.agentProfit.totalCommissionOddFormalas" class="result-item">{{totalCommissionOddIndex+1}}).  {{totalCommissionOddItem}}</p>
                      <p class="result-total">Total : {{result.agentProfit.totalCommissionOdd}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('agent.agentProfit.totalProfitParentPayToMe')}}</td>
                  <td  
                    @mouseover="tooltipActive = 'agentProfit.totalProfit'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentProfit.totalProfit > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{result.agentProfit.totalProfit}}</span>
                    <div class="row">
                      <p class="m0 font8">ForwardAmount = PlayAmount * ForwardPercent </p>
                      <p class="m0 font8">If Win : sum( ((ForwardAmount * parentOddRate) - ForwardAmount) + (ForwardAmount * ParentBonusRate) )</p>
                      <p class="m0 font8">If Lose : sum(-ForwardAmount + (ForwardAmount * ParentBonusRate))</p>
                      <p class="m0 font8">{{$t('formulas')}} : sum(Win&Lose)</p>

                      <div v-if="tooltipActive == 'agentProfit.totalProfit'" class="tooltip-hover">
                        <p v-for="(totalProfitItem, totalProfitIndex ) in result.agentProfit.totalProfitFormalas" class="result-item">
                          <span v-if="totalProfitIndex > 0">{{totalProfitIndex}}).</span>  {{totalProfitItem}}</p>
                        <p class="result-total">Total : {{result.agentProfit.totalProfit}}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'agentOwnPartProfit'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="row">
              <textarea v-model="agentOwnPartProfit.inputFormat" class="input" style="background: #e2e4ef;" rows="10"></textarea>
            </div>
          </div>

          <div class="row mb12">
            <p class="mb0 font10" v-html="formattedText(`Input also support multi row ,every row is an original records : Item | Ball | PlayAmount |  OwnShareHolder |OwnBonusRate| OddsRate | ProfitAfterBonus`)"></p>
            <!-- <p class="mb0 font10" v-html="formattedText(`输入框支持多行数据，每一行的格式如下 ：项目名|球号|原始投注金额|赔率|退水率|输或赢`)"></p> -->
          </div>

          <div class="row txt-center mb32">
            <button @click="agentOwnPartProfitCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('agentOwnPartProfit')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">OccupiedAmount</td>
                  <td width="50%"
                    @mouseover="tooltipActive = 'agentOwnPartProfit.occupiedAmount'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentOwnPartProfit.occupiedAmount > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentOwnPartProfit.occupiedAmount }}</span>
                    <small>{{$t('formulas')}} : Sum (PlayAmount * OwnShareHolder )</small>

                    <div v-if="tooltipActive == 'agentOwnPartProfit.occupiedAmount'" class="tooltip-hover">
                      <p v-for="(occupiedAmountItem, occupiedAmountIndex ) in result.agentOwnPartProfit.occupiedAmountFormalas" class="result-item">{{occupiedAmountIndex+1}}).  {{occupiedAmountItem}}</p>
                      <p class="result-total">Total : {{result.agentOwnPartProfit.occupiedAmount}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="50%">PayBonusToBelow</td>
                  <td width="50%"
                    @mouseover="tooltipActive = 'agentOwnPartProfit.payBonusToBelow'" 
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentOwnPartProfit.payBonusToBelow > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentOwnPartProfit.payBonusToBelow }}</span>
                    <small>{{$t('formulas')}} : Sum ( PlayAmount * OwnShareHolder * OwnBonusRate )</small>

                    <div v-if="tooltipActive == 'agentOwnPartProfit.payBonusToBelow'" class="tooltip-hover">
                      <p v-for="(payBonusToBelowItem, payBonusToBelowIndex ) in result.agentOwnPartProfit.payBonusToBelowFormalas" class="result-item">{{payBonusToBelowIndex+1}}).  {{payBonusToBelowItem}}</p>
                      <p class="result-total">Total : {{result.agentOwnPartProfit.payBonusToBelow}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="50%">ProfitAfterBonus</td>
                  <td width="50%"
                    @mouseover="tooltipActive = 'agentOwnPartProfit.profitAfterBonus'"
                    @mouseout="tooltipActive = ''"
                    style="border-left: 1px solid var(--secondary);position: relative;">
                    <span :class="result.agentOwnPartProfit.profitAfterBonus > -1 ? 'txt-success' : 'txt-danger'" class="bold pr6">{{ result.agentOwnPartProfit.profitAfterBonus }}</span>
                    <small>{{$t('formulas')}} : Sum ( PlayAmount * OwnShareHolder * OwnBonusRate )</small>

                    <div v-if="tooltipActive == 'agentOwnPartProfit.profitAfterBonus'" class="tooltip-hover">
                      <p v-for="(profitAfterBonusItem, profitAfterBonusIndex ) in result.agentOwnPartProfit.profitAfterBonusFormalas" class="result-item">{{profitAfterBonusIndex+1}}).  {{profitAfterBonusItem}}</p>
                      <p class="result-total">Total : {{result.agentOwnPartProfit.profitAfterBonus}}</p>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <div v-if="subAgentTabActive == 'complex'" class="row sub-tab-block">
          <div class="row mv6 mh0">
            <div class="col-left-item">
              (A) Below layer acc profit before bonus (Original Profit)
            </div>
            <div class="col-right-item">
              <input v-model="agent.originalProfit" type="text" class="ctm-input" />
            </div>
          </div>
          <div hidden class="row mv6 mh0">
            <div class="col-left-item">
              (B) Below layer acc profit before bonus (Original Amount)
            </div>
            <div class="col-right-item">
              <input v-model="agent.originalAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              (C) Below layer acc bonus
            </div>
            <div class="col-right-item">
              <input v-model="agent.belowLayerBonusAmount" type="text" class="ctm-input" />
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              (D) Current layer agent shareholder rate(%)
            </div>
            <div class="col-right-item">
              <input v-model="agent.agentShareholdRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              (E) Parent layer agent/admin shareholder rate(%)
            </div>
            <div class="col-right-item">
              <input v-model="agent.parentShareholdRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div hidden class="row mv6 mh0">
            <div class="col-left-item">
              (F) Current layer odd rate ( give to below acc )
            </div>
            <div class="col-right-item">
              <input v-model="agent.parentShareholdRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div hidden class="row mv6 mh0">
            <div class="col-left-item">
              (G) Parent layer agent/admin odd rate ( Give to current acc )
            </div>
            <div class="col-right-item">
              <input type="text" class="ctm-input"/>
            </div>
          </div>
          <div class="row mv6 mh0">
            <div class="col-left-item">
              (H) Current layer bonus amount ( give to below acc )
            </div>
            <div class="col-right-item">
              <input v-model="agent.bonusRate" type="text" class="ctm-input"/>
            </div>
          </div>
          <div hidden class="row mv6 mh0">
            <div class="col-left-item">
              (I) Parent layer agent/admin bonus rate ( Give to current acc )
            </div>
            <div class="col-right-item">
              <input  type="text" class="ctm-input"/>
            </div>
          </div>
          
          <div class="row txt-center mb32">
            <button @click="agentCalculateResult" class="btn-cal">{{$t('calculate')}}</button>
            <button @click="clear('complex')" class="btn-clear ml6">{{$t('clear')}}</button>
          </div>
  
          <div class="row mb32">
              <p class="font16 bold m0 mb10">{{$t('calculateResult')}}</p>
              <table class="table">
                <tr>
                  <td width="50%">How much should pay from current to below acc</td>
                  <td width="50%" style="border-left: 1px solid var(--secondary)">
                    <span :class="result.agent.amountPayToBelow > -1 ? 'txt-success' : 'txt-danger'" class="txt-danger bold mr6">{{result.agent.amountPayToBelow}}</span>
                    <small class="txt-zoom-hover">{{$t('formulas')}} : {{agent.originalProfit}} + {{agent.belowLayerBonusAmount}} = {{result.agent.amountPayToBelow}}</small>
                  </td>
                </tr>
                <tr>
                  <td>How much should get from up admin/agent to current acc</td>
                  <td style="border-left: 1px solid var(--secondary)">
                    <span :class="result.agent.amountGetFromParent > -1 ? 'txt-success' : 'txt-danger'" class="txt-danger bold mr6">{{result.agent.amountGetFromParent}}</span>
                    <small v-if="agent.originalProfit >= 0" class="txt-zoom-hover">{{$t('formulas')}} : ({{agent.originalProfit}} * ({{agent.agentShareholdRate}}/100)) - {{agent.bonusRate}}</small>
                    <small v-else class="txt-zoom-hover">{{$t('formulas')}} : ({{agent.originalProfit}} * ({{agent.agentShareholdRate}}/100)) + {{agent.bonusRate}}</small>
                  </td>
                </tr>
                <!-- <tr>
                  <td>How much make money on Commission Bonus rate</td>
                  <td class="txt-danger bold" style="border-left: 1px solid var(--secondary)">
                    <span class="font18">{{result.agent.amountCommissionBonusRate}}</span>
                  </td>
                </tr>
                <tr>
                  <td>How much current acc earn</td>
                  <td class="txt-danger bold" style="border-left: 1px solid var(--secondary)">
                    <span class="font18">{{result.agent.amountEarn}}</span>
                  </td>
                </tr> -->
              </table>
  
              <!-- <p class="font16 bold m0">Agent : {{$t('calculateResult')}}</p>
              <div class="row">
                <p class="txt-danger bold font18 m0">How much should pay from current to below acc</p>
                <span>{{result.agent.amountPayToBelow}}</span>
              </div>
              <div class="row">
                <p class="txt-danger bold font18 m0">How much should get from up admin/agent to current acc</p>
                <span>{{result.agent.amountGetFromParent}}</span>
              </div>
              <div class="row">
                <p class="txt-danger bold font18 m0">How much make money on Commission Bonus rate</p>
                <span>{{result.agent.amountCommissionBonusRate}}</span>
              </div>
              <div class="row">
                <p class="txt-danger bold font18 m0">How much current acc earn</p>
                <span>{{result.agent.amountEarn}}</span>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>